@import 'mp/mp';

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400&display=swap');

// e.g. $global-font-family: futura-pt, sans-serif;
$global-font-family: $mp-body-font;
$base-heading-font-family: $mp-header-font;
$global-link-color: $mp-secondary;
$global-link-hover-color: $mp-primary;
$global-primary-background: $mp-primary;
$base-heading-font-weight: bold;
$base-heading-color: $mp-primary;
$border-rounded-border-radius: 10px;
$navbar-background: $mp-primary;
$navbar-color-mode: light;
$button-text-transform: none;

$subnav-item-text-transform: none;
$subnav-item-active-color: $mp-secondary;

$navbar-nav-item-text-transform: none;
$navbar-nav-item-font-family: $mp-header-font;
$navbar-nav-item-font-size: 16px;
$navbar-nav-item-padding-horizontal: $mp-base;

$inverse-navbar-nav-item-color: white;
$inverse-navbar-nav-item-hover-color: $mp-secondary;
$inverse-navbar-nav-item-onclick-color: rgba(white, 0.8);
$inverse-navbar-nav-item-active-color: $mp-secondary;

$inverse-button-primary-color: white;
$inverse-button-primary-hover-color: white;
$inverse-button-primary-active-color: white;

$inverse-global-color: white;

$offcanvas-bar-background: $mp-primary;

$card-body-padding-horizontal-l: 30px;
$card-body-padding-vertical-l: 30px;

$card-header-padding-vertical-l: 20px;
$card-header-padding-horizontal-l: 30px;

$alert-primary-background:linear-gradient(45deg,  #00acff 0%,#3080ff 100%);
$alert-primary-color: white;

$button-secondary-background: $mp-primary;
$button-secondary-color: white;
$button-secondary-hover-background: lighten($mp-primary, 10%);
$button-secondary-hover-color: white;
$button-secondary-active-background: darken($mp-primary, 10%);
$button-secondary-active-color: white;

/*
$global-font-size: $mp-base-font-size;
$icon-link-color: white;
$icon-link-hover-color: $mp-muted;
$icon-link-active-color: white;
$global-line-height: 1.3;
$text-lead-line-height: 1.2;
$dotnav-item-width: 20px;
$navbar-nav-item-text-transform: none;
$navbar-nav-item-font-size: $mp-base-font-size;
$inverse-navbar-nav-item-color: white;
$inverse-navbar-nav-item-hover-color: white;
$inverse-navbar-nav-item-onclick-color: white;
$inverse-navbar-nav-item-active-color: white;
$padding-small-padding: $mp-medium;
//$button-large-padding-horizontal: 20px;
$global-color: black;
$button-secondary-background: white;
$button-secondary-color: $mp-primary;
$button-secondary-hover-background: $mp-primary;
$button-secondary-hover-color: white;
$button-secondary-active-background: darken($mp-primary, 10%) !default;
$button-secondary-active-color: white;

$inverse-icon-link-color: white;
$inverse-icon-link-hover-color: white;
$inverse-icon-link-active-color: white;

$form-large-font-size: 1rem;

$global-warning-background: #E88A0F;
$global-success-background: #287F30;
$global-danger-background: #AE162B;
$alert-warning-background: lighten(mix(white, $global-warning-background, 45%), 22.5%); // as discussed with BS

$inverse-search-color: white;*/

@import '~uikit/src/scss/variables-theme.scss';
@import '~uikit/src/scss/mixins-theme.scss';

// Custom mixins / UIkit extensions

@mixin hook-button() {
  border-radius: $border-rounded-border-radius / 2;
  font-family: $mp-body-font;
  transition: all 0.2s ease;
}

@mixin hook-button-hover() {
  transform: scale(1.05);
}

@mixin hook-button-primary() {
  background: linear-gradient(45deg,  #00acff 0%,#3080ff 100%);
  box-shadow: 0 0 15px rgb(0 0 0 / 18%);
}

@mixin hook-inverse-button-primary() {
  border-color: transparent;
}

@mixin hook-card-media-top(){
  border-top-left-radius: $border-rounded-border-radius;
  border-top-right-radius: $border-rounded-border-radius;
}

@mixin hook-card(){
  border-radius: $border-rounded-border-radius;
}

@mixin hook-subnav-item() {
  font-family: $mp-header-font;
}


@import "~uikit/src/scss/uikit-theme.scss";

@import 'mp/mp-mixins';

@import "~air-datepicker/dist/css/datepicker.css";
