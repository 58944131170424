/**
* =============================================================================
* MEDIA BREAKPOINTS
* =============================================================================
*/

$mp-breakpoint-small: 640px !default;
$mp-breakpoint-medium: 960px !default;
$mp-breakpoint-large: 1200px !default;
$mp-breakpoint-xlarge: 1600px !default;
$mp-breakpoint-xsmall-max: ($mp-breakpoint-small - 1) !default;
$mp-breakpoint-small-max: ($mp-breakpoint-medium - 1) !default;
$mp-breakpoint-medium-max: ($mp-breakpoint-large - 1) !default;
$mp-breakpoint-large-max: ($mp-breakpoint-xlarge - 1) !default;
$mp-sizes: 's', 'm', 'l', 'xl';
$mp-breaks: $mp-breakpoint-small, $mp-breakpoint-medium, $mp-breakpoint-large, $mp-breakpoint-xlarge;
$mp-breaks-max: $mp-breakpoint-xsmall-max, $mp-breakpoint-small-max, $mp-breakpoint-medium-max, $mp-breakpoint-large-max;
$mp-height-small: 100px;
$mp-height-medium: 200px;
$mp-height-large: 325px;
$mp-height-xlarge: 600px;
$mp-directions: 'top', 'right', 'bottom', 'left';
$mp-spacing: 'margin', 'padding';
$mp-sizes-full: 'small', 'medium', 'large', 'xlarge';

$mp-xsmall-margin: 5px;

/**
* =============================================================================
* BASE VARIABLES
* =============================================================================
*/

// Colours
$mp-primary: #111630;
$mp-secondary: #1598ff;
$mp-tertiary: #777777;
$mp-muted: #f6f7f9;

$mp-colors: $mp-primary, $mp-secondary, $mp-tertiary, '#151618', '#fff', $mp-muted;
$mp-color-names: 'primary', 'secondary', 'tertiary', 'black', 'white', 'muted';

// Spacing
$mp-base: 24px; // TODO: Set to base spacing - see design / pattern library

// Any other custom base variables
// $mp-my-var: initial;
$mp-body-font: 'Source Sans Pro', sans-serif;
$mp-header-font: 'Wavehaus', sans-serif;
$mp-base-font-size: 16px;
$mp-base-line-height: 1.2;

/**
* =============================================================================
* UTILITY
* =============================================================================
*/

$mp-heights: 10;
$mp-weights: 300, 400, 500, 600, 700;
$mp-prefixes: webkit ms moz o spec;

/**
* =============================================================================
* SPACING
* =============================================================================
*/
$mp-xxxsmall: $mp-base * 0.25; // 6px, not in guide
$mp-xxsmall: $mp-base * 0.33; // 7.92px, not in guide
$mp-xsmall: $mp-base * 0.5; // 12px, not in guide
$mp-small: $mp-base * 0.75; // 18px, in guide, says 16px
$mp-medium: $mp-base * 1.25; // 30px, in guide, says 32px
$mp-large: $mp-base * 1.5; // 36px, not in guide
$mp-xlarge: $mp-base * 1.75; // 42px, not in guide
$mp-xxlarge: $mp-base * 2; // 48px, in guide
$mp-xxxlarge: $mp-base * 2.5; // 60px, guide says 64px

/**
* =============================================================================
* SCSS PLACEHOLDERS
* =============================================================================
*/

// Placeholder for all H* elements
h1, h2, h3,
h4, h5, h6 {
  @extend %headings !optional;
}

// Placeholder for common text elements
%headings, p, ul, li {
  @extend %text !optional;
}

p:last-of-type {
  margin-bottom: 0;
}
