@import 'mp-variables';
@import 'mp-functions';
@import 'mp-mixins';
@import 'mp-fonts';

@include mp-height-utilities();
@include mp-color-utilities();
@include mp-font-size-utilities();
@include mp-font-weight-values();
@include mp-z-index-values();
@include mp-spacing-responsive();
@include mp-padding-utilities();


.mp-background-gradient-white-grey-diagonal {
  background: linear-gradient(172deg, white 0%, white 50%, #f6f7f9 50%, #f6f7f9 100%);
}

.mp-background-gradient-grey-white-diagonal {
  background: linear-gradient(-8deg, white 0%, white 50%, #f6f7f9 50%, #f6f7f9 100%);
}

.mp-background-gradient-transparent-white-diagonal {
  background: linear-gradient(172deg, transparent 0%, transparent 49.5%, white 50%, white 100%);
}

.mp-background-gradient-transparent-grey-diagonal {
  background: linear-gradient(172deg, transparent 0%, transparent 49.5%, #f6f7f9 50%, #f6f7f9 100%);
}

.mp-background-gradient-blue {
  background: linear-gradient(45deg,  #00acff 0%,#3080ff 100%);
}

.mp-overlay-gradient-blue {
  background: linear-gradient(to right,  rgba(17,22,48,0.71) 0%,rgba(17,22,48,0) 100%);
}

.mp-font-header {
  font-family: $mp-header-font;
}

.mp-font-body {
  font-family: $mp-body-font;
}

.mp-red-dot {
  height: 25px;
  width: 25px;
  background-color: #ff0000;
  border-radius: 50%;
  display: inline-block;
}

.mp-green-dot {
  height: 25px;
  width: 25px;
  background-color: #008000;
  border-radius: 50%;
  display: inline-block;
}

.mp-amber-dot {
  height: 25px;
  width: 25px;
  background-color: #ffa500;
  border-radius: 50%;
  display: inline-block;
}

.mp-other{
    margin-top:10px;
}
