@font-face {
  font-family: 'Wavehaus';
  src: url(../../fonts/Wavehaus-95SemiBold.woff2) format('woff2'),
  url(../../fonts/Wavehaus-95SemiBold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wavehaus';
  src: url(../../fonts/Wavehaus-66Book.woff2) format('woff2'),
  url(../../fonts/Wavehaus-66Book.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
