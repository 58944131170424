/**
 * mp-mixins.scss
 * =============================================================================
 * Mixins let you make groups of CSS declarations that you want to reuse
 * throughout your site (e.g. for vendor prefixes). This is different to
 * functions, which focus on calculating the value of a single style attribute
 *
 */

@mixin mp-padding-utilities {

  $paddings: $mp-xxsmall, $mp-xsmall, $mp-large, $mp-xxlarge;
  @each $size-full in $mp-sizes-full {
    @each $direction in $mp-directions {
      $k: index($mp-sizes-full, $size-full);
      $padding-size: nth($paddings, $k);

      // Generate spacing remove classes for all 4 directions
      .mp-padding-#{$size-full}-#{$direction} {
        padding-#{$direction}: $padding-size !important;
      }
    }
  }
}

@mixin mp-spacing-responsive() {

  @each $spacing in $mp-spacing {
    @each $direction in $mp-directions {
      // Generate spacing remove classes for all 4 directions
      .mp-#{$spacing}-remove-#{$direction} {
        #{$spacing}-#{$direction}: 0 !important;
      }
    }
  }

  @each $size in $mp-sizes {
    $k: index($mp-sizes, $size);
    $break: nth($mp-breaks, $k);
    $break-max: nth($mp-breaks-max, $k);

    // These rules will apply like normal UIKit rules, affecting widths on or
    // above a breakpoint (e.g. 'mp-remove-padding-top@s' will remove top
    // padding for all screen sizes small and above)
    @media (min-width: $break) {
      @each $spacing in $mp-spacing {
        // Remove directional spacing
        @each $direction in $mp-directions {
          // Generate spacing remove classes for all 4 directions + 4 breakpoints
          .mp-#{$spacing}-remove-#{$direction}\@#{$size} {
            #{$spacing}-#{$direction}: 0 !important;
          }
        }
        // Remove all spacing @ specified size and above
        .mp-#{$spacing}-remove\@#{$size} {
          #{$spacing}: 0 !important;
        }

        // Remove vertical spacing
        .mp-#{$spacing}-remove-vertical\@#{$size} {
          #{$spacing}-top: 0 !important;
          #{$spacing}-bottom: 0 !important;
        }
        // Remove horizontal spacing
        .mp-#{$spacing}-remove-horizontal\@#{$size} {
          #{$spacing}-left: 0 !important;
          #{$spacing}-right: 0 !important;
        }
      }
    }

    // These rules are slightly different, and will apply like UIKit 'visible'
    // rules, affect widths below certain breakpoints (e.g.
    // 'mp-remove-padding-top-until@s' will remove top padding for
    // all screen sizes small and BELOW)
    @media (max-width: $break-max) {
      @each $spacing in $mp-spacing {

        .mp-#{$spacing}-remove-until\@#{$size} {
          #{$spacing}: 0 !important;
        }

        @each $direction in $mp-directions {
          // Generate spacing remove classes for all 4 directions + 4 breakpoints
          .mp-#{$spacing}-remove-#{$direction}-until\@#{$size} {
            #{$spacing}-#{$direction}: 0 !important;
          }
        }

        // Remove vertical spacing
        .mp-#{$spacing}-remove-vertical-until\@#{$size} {
          #{$spacing}-top: 0 !important;
          #{$spacing}-bottom: 0 !important;
        }
        // Remove horizontal spacing
        .mp-#{$spacing}-remove-horizontal-until\@#{$size} {
          #{$spacing}-left: 0 !important;
          #{$spacing}-right: 0 !important;
        }
      }
    }
  }
}

/**
 * Generate sensible z-index values
 */
@mixin mp-z-index-values() {
  @for $i from 1 through 9 {
    .mp-z-index-#{$i*100} {
      z-index: #{$i*100};
    }
  }
}

/**
 * Generate sensible font-weight values
 */
@mixin mp-font-weight-values() {
  @for $i from 1 through 9 {
    .mp-font-weight-#{$i*100} {
      font-weight: #{$i*100};
    }
  }
}

/**
 * Generate sensible font-size values
 */
@mixin mp-font-size-utilities() {

  $font-sizes: 0.6, 0.8, 1.2, 1.4;
  $font-size-names: "xsmall", "small", "large", "xlarge";

  @each $size in $font-sizes {
    $k: index($font-sizes, $size);
    $name: nth($font-size-names, $k);

    .mp-font-size-#{$name} {
      font-size: #{$size}rem;
    }
  }
}

/**
 * Generate handy color utilities (background, color)
 */
@mixin mp-color-utilities() {
  @each $color in $mp-colors {
    $k: index($mp-colors, $color);
    $name: nth($mp-color-names, $k);

    .mp-color-#{$name} {
      color: #{$color} !important;
    }
    .mp-background-#{$name} {
      background-color: #{$color};
    }
  }
}

/**
 * Generate handy border utilities (background, color)
 */
@mixin mp-border-utilities() {
  @each $color in $mp-colors {
    $k: index($mp-colors, $color);
    $name: nth($mp-color-names, $k);

    .mp-border-solid-#{$name} {
      border-color: #{$color};
      border-width: 1px;
      border-style: solid;
    }
  }
}

@mixin visually-hidden() {
  // sass-lint:disable no-important
  position: absolute !important;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin visually-shown($position: inherit) {
  // sass-lint:disable no-important
  position: $position !important;
  overflow: auto;
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
}

/**
Generate a variety of responsive height classes based on uk-height-small / medium / large.
 */
@mixin mp-height-utilities(){

  $multipliers: 0.7, 0.9, 1, 1.4;
  $base-heights: $mp-height-small, $mp-height-medium, $mp-height-large, $mp-height-xlarge;
  $mp-sizes-full: 'small', 'medium', 'large', 'xlarge';

  @each $size-name in $mp-sizes-full {

    $i: index($mp-sizes-full, $size-name);
    $base-value: nth($base-heights, $i);

    .mp-height-#{$size-name} { // For below S, or not specified
      height: #{$base-value*0.7};
    }
  }

  @each $size in $mp-sizes {
    $k: index($mp-sizes, $size);
    $break: nth($mp-breaks, $k);
    $break-max: nth($mp-breaks-max, $k);
    $multiplier: nth($multipliers, $k);

    // Generate response heights (like uk-height-medium, but with responsive bobbins
    @media (min-width: $break) {
      @each $size-name in $mp-sizes-full {
        $j: index($mp-sizes-full, $size-name);
        $base-value: nth($base-heights, $j);

        .mp-height-#{$size-name} {
          height: #{$base-value*$multiplier};
        }
      }
    }
  }
}

@mixin s-up {
  @media (min-width: $mp-breakpoint-small) { @content; }
}
@mixin m-up {
  @media (min-width: $mp-breakpoint-medium) { @content; }
}
@mixin l-up {
  @media (min-width: $mp-breakpoint-large) { @content; }
}
@mixin xl-up {
  @media (min-width: $mp-breakpoint-xlarge) { @content; }
}

@mixin xs-down {
  @media (max-width: $mp-breakpoint-xsmall-max) { @content; }
}
@mixin s-down {
  @media (max-width: $mp-breakpoint-small-max) { @content; }
}
@mixin m-down {
  @media (max-width: $mp-breakpoint-medium-max) { @content; }
}
@mixin l-down {
  @media (max-width: $mp-breakpoint-large-max) { @content; }
}

@mixin mp-flex-order-responsive() {

  @for $i from 1 through 50 {
    .mp-flex-#{$i} {
      order: $i;
    }
  }

  @each $size in $mp-sizes {
    $k: index($mp-sizes, $size);
    $break: nth($mp-breaks, $k);
    $break-max: nth($mp-breaks-max, $k);

    @media (min-width: $break) {
      @for $i from 1 through 50 {
        .mp-flex-#{$i}\@#{$size} {
          order: $i;
        }
      }
    }
  }
}

@mixin prefix($property, $value, $prefixes) {
  @if length($prefixes) == 0 {
    $prefixes: webkit moz ms o spec;
  }
  @each $prefix in $prefixes {
    @if $prefix == webkit {
      -webkit-#{$property}: $value;
    } @else if $prefix == moz {
      -moz-#{$property}: $value;
    } @else if $prefix == ms {
      -ms-#{$property}: $value;
    } @else if $prefix == o {
      -o-#{$property}: $value;
    } @else if $prefix == spec {
      #{$property}: $value;
    } @else  {
      @warn 'Unrecognized prefix: #{$prefix}';
    }
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > img, > a, > video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin mp-spacing-xsmall() {
  @each $spacing in $mp-spacing {
    @each $direction in $mp-directions {
      // Generate spacing remove classes for all 4 directions
      .mp-#{$spacing}-xsmall-#{$direction} {
        #{$spacing}-#{$direction}: $mp-xsmall-margin !important;
      }
    }
  }
}

@mixin mp-flex-vertical-responsive {
  @each $size in $mp-sizes {
    $k: index($mp-sizes, $size);
    $break: nth($mp-breaks, $k);

    @media (min-width: $break) {
      .uk-flex-top\@#{$size} {
        align-items: flex-start;
      }
      .uk-flex-middle\@#{$size} {
        align-items: center;
      }
      .uk-flex-bottom\@#{$size} {
        align-items: flex-end;
      }
    }
  }
}
